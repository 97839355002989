@import "../../../styles/variables";

.rijksoverheid-block {
  margin: 20px 0 0 0;
  width:100%;
  background-color: $blue-darkest;
  color: #ffffff;
  padding:15px;

  a {
    margin-top: 10px;
    color: #ffffff;
    position: relative;
    display: block;
    width: auto;
    font-weight: bold;
    font-size: 1em;
    line-height: 1.625rem;
    padding-left: 16px;

    &:before {
      font-weight: normal;
      font-family: "RO Icons";
      display: inline-block;
      vertical-align: middle;
      content: "";
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 0px 8px 0 0;
      font-size: 10px;
      position: absolute;
      left: 0px;
      top: 0px;
      line-height: inherit;
    }

    &:visited {
      color: #ffffff;

      &:before {
        color: #ffffff;
      }
    }

    &:focus {
      color: #ffffff;
      outline: 2px dotted #ffffff;
    }
  }

  @media screen and (min-width: $break-m) {
    margin: 0 0 0 10%;
    width:30%;
  }
}
