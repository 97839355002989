@import "../../styles/variables";

.chart-areaspline {
  .legend-wrapper {
    padding: 8px 16px;
    border-radius: 2px;
  }
  .legend-label {
    font-weight: normal;
    color: #FFF;
  }
  .highcharts-legend-item rect{
    display:none;
  }
  .highcharts-legend-item-hidden .legend-wrapper {
    background: #CCC;
  }
}

.chart {
  &__title {
    &--blue {
      color: $blue-bright;
    }
  }

  &__container--half-width {
    width: 50%;
  }

  &__intro {
    font-size: 1rem;
  }

  &__body {
    margin: 20px 0 0 0;
    box-shadow: 0px 2px 5px 3px rgba(204, 204, 204, 0.4);

    img {
      width: 100%;
    }
  }

  &__disclaimer {
    font-size: 0.9em;
    font-style: italic;
    margin: 10px 0;
  }

  &__meta {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 10px 20px 0 0;

    span {
      display: block;
      font-size: 0.8em;
      font-style: italic;
    }
  }

  &__subtext {
    margin: 20px 0 0 0;
  }
}

.trend-parameters {
  &__wrapper {
    & > div {
      &:first-child {
        display: inline-block;
        width: 100%;

        @media screen and (min-width: $break-xl) {
          width: 80%;
        }
      }
    }
  }

  &__actual-wrapper {
    align-items: center;
    display: inline-flex;
    height: 100%;
  }

  &__actual-card {
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: block;
    margin: 16px;
    padding: 20px;
    text-align: center;

    @media screen and (min-width: $break-xl) {
      margin: 95px 0 0 5px;
    }
  }

  &__actual-title {
    color: #007BC7;
    display: block;
    font-size: 18px;
    font-weight: bold;
  }

  &__actual-text {
    display: block;
    font-size: 24px;
    font-weight: bold;
  }

  &__actual-meta {
    font-style: italic;
    font-size: 18px;
    color: #666666;
  }
}
