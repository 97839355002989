table {
  th {
    background-color: #F3F3F3;
    padding: 50px;
    font-weight: bold;
  }
  td {
    background-color: #FFFFFF;
  }
  td:nth-child(1) {
    background-color: #F3F3F3;
    font-weight: bold;
  }
}
