@import "../../../styles/variables";

.menu {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 20px;
  margin-top: 20px;

  .menu-link {
    display: flex;
    flex-direction: column;
    padding: 16px 8px;
    border: 1px solid $blue-darkest;
    font-weight: 700;
    text-decoration: none;
    height: 100%;

    &__title {
      color: #000;
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin-bottom: 0.75rem;
    }

    &__description {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $blue-darkest;

      .menu-link__title,
      .menu-link__description {
        color: #fff;
      }
    }
  }
}

@media all and (min-width: $break-s) {
  .menu {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media all and (min-width: $break-m) {
  .menu {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (min-width: $break-l) {
  .menu {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media all and (min-width: $break-xl) {
  .menu {
    grid-template-columns: repeat(4, 1fr);
  }
}
