.site-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.125rem;

  .wrapper {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -16px;
    padding: 2.5rem 0;

    .column {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0;   
      padding: 0 16px;

      @media screen and (min-width: 768px) {
        flex: 0 0 40%;
        max-width: 40%;
        margin: 0 0 0 60%;
      }

      @media screen and (min-width: 992px) {
        flex: 0 0 30%;
        max-width: 30%;
        margin: 0 0 0 70%;
      }

      h2 {
        margin: 0 0 1rem 0;
      }

      a {
        padding: 8px 0 8px 20px;
        position: relative;

        &:before {
          display: inline-flex;
          align-items: center;
          content: "";
          font-family: "RO Icons";
          font-size: 0.7rem;
          font-weight: 700;
          width: 20px;
          padding-top: 6.4px;
          height: 32px;
          color: #ffffff;
          text-indent: -0.1em;
          position: absolute;
          top: -1px;
          left: 0;
        }
      }
    }
  }
}
