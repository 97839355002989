$website-width-xl: 1200px;
$website-width-l: 1024px;
$website-width-m: 960px;

// Responsive breaks.
$break-xxl: 1200px;
$break-xl: 940px;
$break-l: 768px;
$break-m: 576px;
$break-s: 480px;

// Colors.
$purple:       #42145F;
$red:          #D52B1E;
$pink:         #F2BFBB;
$orange:       #FFB612;
$orange-light: #FFE9B7;

$blue-darkest: #01689B;
$blue-bright:  #007BC7;
$blue-grey:    #CCE0F1;
$blue-light:   #B2D7EE;
$blue-lightest:#D9EBF7;
$blue:         #8FCAE7;

$green-dark:   #275937;
$green:        #39870C;
$green-light:  #C3DBB6;

$grey:         #696969;
$grey-light:   #F3F3F3;
