h1, h2, h3 {
    font-weight: 700;
}

h1 {
    font-size: 2rem;
    line-height: 2.25rem;
    margin: 0 0 1.875rem;
}

h2 {
    font-size: 1.625rem;
    line-height: 2rem;
    margin: 0 0 1.25rem;
}

h3 {
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: 0 0 0.75rem;
}