@media print {
  .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}

.content__block {
  margin-top: 3.75rem;
}

// Page Earthquakes Params cards
.incident-params-wrapper {
  display: flex;
  flex-wrap: wrap;

  .chart {
    width: 100%;
    flex: 0 0 100%;
    margin-right: 20px;
  }

  @media screen and (min-width: $break-m) {
    .chart {
      width: 35%;
      flex: 0 0 35%;
    }
  }

  .incident-params-value {
    color: #01689B;
    font-size: 50px;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-weight: 900;

    &--grey {
      color: #565656;
    }

    &--green {
      color: #39870c;
    }

    &--red {
      color: #d52b1e;
    }
  }

  .incident-params-meta {
    color: #666666;
    font-style: italic;
    font-size: 0.8rem;
  }
}

.content {
  &__header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__intro {
    flex: 1 1 70%;
  }

  @media screen and (min-width: $break-m) {
    &__header {
      flex-direction: row;
    }
  }
}

.trendparameters img {
  width: 100%;
}

.page-accessibility-statement {
  .accessibility {
    &__link {
      display: block;
    }

    &__img {
      margin: 30px 0 0 0;
      width: 100%;
      max-width: 600px;
    }
  }
}

.map iframe {
  border:none;
  height: calc(100vh - 194px);
  margin-bottom: -40px;
  width: 100%;
}

// Page Vlekkentabel styling
.content__block__vlekken {
  margin-top: 2.75rem;
  
  h2 {
    font-size: 1.125rem;
  }

  .card {
    &__title {
      font-style: italic;
      text-decoration: underline;
    }
    
    &__body {
      box-shadow: 0px 2px 5px 3px rgba(204, 204, 204, 0.4);
      margin: 1rem 0 1rem 0;
      padding: 1rem;
      font-style: italic;

      i {
        font-style: normal;
      }
    }
  }

  .list {
    &__bullet-list {
      padding-left: 2rem;
    }
    
    &__bullet-list-item {
      list-style-type: disc;
      margin-bottom: 0.8125rem;
      padding-left: 1rem;
    }

    &__dash-list {
      padding-left: 1rem;
    }

    &__dash-list-item {
      list-style-type: none;
      margin-bottom: 0.8125rem;
      padding-left: 1.5rem;
    }

    &__dash-list-item:before {
      content: "–";
      position: absolute;
      margin-left: -1.5rem;
    }

    &__unstyled-list {
      padding-left: 1rem;
    }
    
    &__unstyled-list-item {
      list-style-type: none;
      margin-bottom: 0.8125rem;
      padding-left: 1rem;
    }
  }
  
  .paragraph-title {
    font-weight: normal;
    text-decoration: underline;
  }
}

.table__vlekken {
  max-width: 85%;
  overflow-x: scroll;
  padding-top: 0.8125rem;

  th, td {
    border: solid;
    border-color: #e6e6e6;
    padding: 0.75rem;
    vertical-align: middle;
    width: 10%;
  }

  &__intro {
    font-size: 1rem;
    margin-bottom: 0.8125rem;
  }

  &__overview {
    max-width: 85%;

    td:nth-child(1) {
      font-weight: normal;
    }
  }

  .first-row {
    text-align: center;
  }

  .colored-cell {
    background-color: #C6B8CE;
  }
}
