@mixin center-page($website-width) {
  max-width: $website-width;
  margin: 60px auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
}

.container {
    @include center-page($website-width-xl);
}

.container-l {
    @include center-page($website-width-l);
}

.container-m {
    @include center-page($website-width-m);
}

.container-no-gap {
  width: 100%;
  box-sizing: border-box;
}
