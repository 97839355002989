.skiplink {
  color: #fff;
  background-color: #01689b;
  position: absolute;
  top: -100vh;
  left: -100vw;
  text-decoration: none;
  font-size: 1.26562rem;
  line-height: 1.58025;
  width: auto;
  min-height: 44px;
  padding: 0.75rem 1rem;
  cursor: pointer;

  &:focus {
    top: 15px;
    left: 15px;
    outline: 2px dotted #000000;
    color: #ffffff;
  }

  &:visited {
    color: #ffffff;
  }
}
